<template>
  <v-card
    class="cursor"
    tile
    :ripple="false"
    :class="topBorderClass"
    v-if="mission.id"
    @click="updateMission"
  >
    <v-card-title class="pb-0 pt-2 no-flex-wrap">
      <v-spacer></v-spacer>
      <actions-menu
        :canEdit="
          mission.status === statuses.active ||
          mission.status === statuses.pending
        "
        :canClone="
          mission.status !== statuses.active &&
          mission.status !== statuses.pending
        "
        @edit="updateMission"
        @clone="copyMission"
        @delete="deleteMission"
      ></actions-menu>
    </v-card-title>
    <v-card-text class="pt-0 pb-1">
      <span
        v-if="!isDetailPage"
        @click.stop="navigateToDetailPage"
        class="text-h5 font-weight-light app-blue--text router-link two-lines"
      >
        {{ mission.name }}
      </span>
      <span v-else class="text-h5 font-weight-medium two-lines">
        {{ mission.name }}
      </span>
      <div class="text-subtitle-1 app-dark-gray--text font-weight-medium">
        {{ mission.notes }}
      </div>
      <span class="text-body-2 font-weight-light">
        {{ mission.from | dateFormat }} -
        {{ mission.to | dateFormat }}
      </span>
    </v-card-text>
    <v-card-actions class="pt-0 pb-1">
      <mission-progress-bar :mission="mission"></mission-progress-bar>
    </v-card-actions>
    <v-card-actions class="pt-2">
      <mission-status-label :mission="mission"></mission-status-label>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-3">
        <gold-label
          :gold="mission.gold"
          :text-color="textGoldColor"
        ></gold-label>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import ActionsMenu from '../shared/ActionsMenu.vue';
import MissionStatusLabel from './MissionStatusLabel';
import MissionProgressBar from './MissionProgressBar';
import ManageMissionModal from './ManageMissionModal';
import GoldLabel from '../shared/GoldLabel';
import ConfirmModal from '../shared/ConfirmModal';
import colorsEnum from '../../enums/colors';
import missionStatuses from '../../enums/mission.statuses';

export default {
  name: 'dashboard-mission-card',
  components: {
    ActionsMenu,
    MissionStatusLabel,
    MissionProgressBar,
    GoldLabel
  },
  props: {
    topBorderClass: {
      type: String,
      default: 'border-top-blue'
    },
    isDetailPage: {
      type: Boolean,
      default: false
    },
    mission: Object,
    update: Function,
    copy: Function,
    del: Function
  },
  computed: {
    textGoldColor() {
      const { gold } = this.mission;

      if (gold >= 0 && gold <= 4999) {
        return `${colorsEnum.green}--text`;
      } else if (gold > 4999 && gold <= 7999) {
        return `${colorsEnum.orange}--text`;
      } else {
        return `${colorsEnum.red}--text`;
      }
    },
    statuses() {
      return missionStatuses;
    }
  },
  methods: {
    navigateToDetailPage() {
      this.$router.push({ path: `/missions/${this.mission.id}` });
    },
    async updateMission() {
      const payload = await this.$root.$modal.show(ManageMissionModal, {
        selectedMission: { ...this.mission }
      });

      if (!payload) return;

      this.update(payload);
    },
    async copyMission() {
      const payload = await this.$root.$modal.show(ManageMissionModal, {
        selectedMission: { ...this.mission },
        copy: true
      });

      if (!payload) return;

      this.copy({ id: this.mission.id, ...payload });
    },
    async deleteMission() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('missions.deleteMissionConfirm')
      });

      if (!confirmed) return;

      this.del(this.mission.id);
    }
  }
};
</script>
