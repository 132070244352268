<template>
  <div>
    <v-card :class="topBorderClass" tile>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="app-blue--text font-weight-light">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          depressed
          color="app-blue"
          class="mr-0"
          v-if="hasTasks"
          @click="showArchivedTasks()"
        >
          <v-icon>mdi-archive</v-icon>
        </v-btn>
        <v-btn
          color="app-blue"
          text
          icon
          :disabled="!canAddTasks"
          @click="openGenerateTasks()"
        >
          <v-icon>mdi-creation</v-icon>
        </v-btn>
        <v-btn
          :disabled="!canAddTasks"
          color="app-blue"
          text
          icon
          @click="open()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="!hasTasks" class="text-center pb-2">
        <span class="text-subtitle-1 font-weight-light">
          {{ $t('missions.missionHasNoTasks') }}
        </span>
      </div>
      <div v-else class="py-2">
        <mission-tasks-types-filter
          @onTasksFilterChange="onTasksFilterChange"
        ></mission-tasks-types-filter>
      </div>
    </v-card>
    <div v-if="hasTasks">
      <div v-for="tasksList of tasksLists" :key="tasksList.name">
        <div v-if="filters[tasksList.name] || !hasSelectedFilters">
          <tasks-list
            :title="$t(tasksList.i18nKey)"
            :tasks="tasksList.tasks"
            :topBorderClass="tasksList.borderClass"
            :titleTextColorClass="tasksList.textClass"
          >
            <template #default="{ task }">
              <mission-task :task="task" slot="task"></mission-task>
            </template>
          </tasks-list>
        </div>
      </div>
      <div id="mission-archived-tasks">
        <tasks-list
          v-if="toggleArchivedTasks"
          topBorderClass="border-top-red"
          titleTextColorClass="app-red--text"
          :title="$t('tasks.archived')"
          :tasks="archivedTasks"
          :closeTasksPanel="closeArchivedTasks"
        >
          <template #default="{ task }">
            <mission-task :task="task" slot="task"></mission-task>
          </template>
        </tasks-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TasksList from '../tasks/TasksList';
import MissionTask from './MissionTask';
import ManageMissionTaskModal from './ManageMissionTaskModal';
import GenerateMissionTasksModal from './GenerateMissionTasksModal';
import BuyGemsModal from '../store/BuyGemsModal';
import MissionTasksTypesFilter from './MissionTasksTypesFilter';
import missionStatuses from '@/enums/mission.statuses';
import taskTypes from '@/enums/task.types';
import {
  ADD_MISSION_TASK,
  UPDATE_MISSION_TASKS_TYPES_FILTER
} from '@/store/missions/tasks/types';
import tasksListsService from '../../services/tasks.lists.service';

const ARCHIVED_TASKS_SELECTOR = '#mission-archived-tasks';

export default {
  name: 'mission-tasks',
  components: {
    MissionTasksTypesFilter,
    TasksList,
    MissionTask
  },
  props: {
    mission: {
      type: Object
    },
    topBorderClass: {
      type: String,
      default: 'border-top-blue'
    },
    title: String
  },
  data() {
    return {
      toggleArchivedTasks: false
    };
  },
  computed: {
    ...mapGetters(['missionTasksTypesFilter']),
    hasTasks() {
      return this.mission.tasks.length > 0;
    },
    canAddTasks() {
      return (
        !this.mission.group &&
        (this.mission.status === missionStatuses.active ||
          this.mission.status === missionStatuses.pending)
      );
    },
    todos() {
      return this.mission.tasks.filter(
        t => t.type === taskTypes.todo && !t.archived
      );
    },
    dailies() {
      return this.mission.tasks.filter(
        t => t.type === taskTypes.daily && !t.archived
      );
    },
    weeklies() {
      return this.mission.tasks.filter(
        t => t.type === taskTypes.weekly && !t.archived
      );
    },
    archivedTasks() {
      return this.mission.tasks.filter(t => t.archived);
    },
    hasSelectedFilters() {
      return this.missionTasksTypesFilter.some(f => f.selected);
    },
    filters() {
      return this.missionTasksTypesFilter.reduce((acc, filter) => {
        acc[filter.type] = filter.selected;
        return acc;
      }, {});
    },
    tasksLists() {
      return tasksListsService.getTasksListsConfiguration(
        this.todos,
        this.dailies,
        this.weeklies
      );
    }
  },
  methods: {
    async open() {
      const payload = await this.$root.$modal.show(ManageMissionTaskModal, {
        selectedTypeFilter: this.getSelectedFilterType()
      });

      if (!payload) return;

      await this.$store.dispatch(ADD_MISSION_TASK, {
        missionId: this.mission.id,
        ...payload
      });
    },
    async openGenerateTasks() {
      const payload = await this.$root.$modal.show(GenerateMissionTasksModal);

      if (payload?.status) {
        this.$root.$modal.show(BuyGemsModal);
      }
    },
    getSelectedFilterType() {
      return Object.keys(this.filters).find(k => this.filters[k]);
    },
    onTasksFilterChange(type) {
      this.$store.dispatch(UPDATE_MISSION_TASKS_TYPES_FILTER, type);
    },
    async showArchivedTasks() {
      this.toggleArchivedTasks = true;
      this.$vuetify.goTo(ARCHIVED_TASKS_SELECTOR, { easing: 'easeOutQuint' });
    },
    closeArchivedTasks() {
      this.toggleArchivedTasks = false;
    }
  }
};
</script>
