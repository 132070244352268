<template>
  <v-card class="pa-2" tile :ripple="false">
    <v-container class="pa-0 pt-3">
      <v-row align="center" justify="center">
        <v-col cols="2" class="pt-0">
          <v-btn
            v-if="task.selected"
            text
            icon
            large
            color="app-green"
            @click.stop="toggleTask()"
          >
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            icon
            large
            color="app-green"
            @click.stop="toggleTask()"
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" class="pt-0 pl-md-0">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-1 pb-0">
      <v-chip color="app-blue" dark small class="tag">
        <span class="font-weight-bold text-caption">
          {{ $t(`tasks.${task.type}`) }}
        </span>
      </v-chip>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <gold-label :gold="task.gold" :text-color="goldColorText"></gold-label>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import GoldLabel from '../shared/GoldLabel';
import tasksGoldColorMixin from '../../mixins/task.gold.color.mixin';

export default {
  name: 'generated-mission-task',
  components: {
    GoldLabel
  },
  props: {
    task: Object
  },
  mixins: [tasksGoldColorMixin],
  methods: {
    async toggleTask() {
      this.task.selected = !this.task.selected;

      this.$emit('toggleTask');
    }
  }
};
</script>
