import colorsEnum from '../enums/colors';

export default {
  computed: {
    goldColor() {
      const { gold } = this.task;
      if (gold >= 100 && gold <= 499) {
        return colorsEnum.green;
      } else if (gold > 499 && gold <= 799) {
        return colorsEnum.orange;
      } else {
        return colorsEnum.red;
      }
    },
    goldColorText() {
      const { gold } = this.task;
      if (gold >= 100 && gold <= 499) {
        return `${colorsEnum.green}--text`;
      } else if (gold > 499 && gold <= 799) {
        return `${colorsEnum.orange}--text`;
      } else {
        return `${colorsEnum.red}--text`;
      }
    }
  }
};
