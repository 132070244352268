<template>
  <v-card class="cursor pa-2" @click="editTask" tile :ripple="false">
    <v-container class="pa-0">
      <v-card-title class="py-0 mb-2 no-flex-wrap">
        <v-spacer></v-spacer>
        <actions-menu
          :canEdit="!task.completed"
          :canArchive="task.completed && !task.archived"
          :canClone="task.completed && task.archived"
          @edit="editTask"
          @archive="archiveTask"
          @clone="copyTask"
          @delete="deleteTask"
          :disabled="!canManageTask"
        ></actions-menu>
      </v-card-title>
      <v-row align="center" justify="center">
        <v-col cols="2" class="pt-0">
          <v-btn
            v-if="task.completed"
            text
            icon
            large
            color="app-green"
            :disabled="!isMissionActive || canCopy"
            @click.stop="reopenTask()"
          >
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            icon
            large
            color="app-green"
            :disabled="!isMissionActive"
            @click.stop="completeTask()"
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" class="pt-0 pl-md-0">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-1 pb-0">
      <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
      <div v-else>&nbsp;</div>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <gold-label :gold="task.gold" :text-color="goldColorText"></gold-label>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/shared/ConfirmModal';
import ManageMissionTaskModal from './ManageMissionTaskModal';
import ActionsMenu from '../shared/ActionsMenu';
import TaskTags from '../tasks/TaskTags';
import GoldLabel from '../shared/GoldLabel';
import missionStatuses from '../../enums/mission.statuses';
import {
  COMPLETE_MISSION_TASK,
  REOPEN_MISSION_TASK,
  UPDATE_MISSION_TASK,
  ARCHIVE_MISSION_TASK,
  COPY_MISSION_TASK,
  DELETE_MISSION_TASK
} from '../../store/missions/tasks/types';
import tasksGoldColorMixin from '../../mixins/task.gold.color.mixin';
import multiNotificationsMixin from '../../mixins/multi.notifications.mixin';

export default {
  name: 'mission-task',
  components: {
    ActionsMenu,
    GoldLabel,
    TaskTags
  },
  props: {
    task: Object
  },
  mixins: [tasksGoldColorMixin, multiNotificationsMixin],
  computed: {
    ...mapGetters(['currentMission']),
    isMissionActive() {
      return this.currentMission.status === missionStatuses.active;
    },
    isMissionActiveOrPending() {
      return (
        this.isMissionActive ||
        this.currentMission.status === missionStatuses.pending
      );
    },
    canManageTask() {
      return (
        this.isMissionActiveOrPending ||
        this.currentMission.status === missionStatuses.pending
      );
    },
    canCopy() {
      return this.task.completed && this.task.archived;
    }
  },
  methods: {
    async completeTask() {
      const { diff } = await this.$store.dispatch(COMPLETE_MISSION_TASK, {
        missionId: this.currentMission.id,
        task: this.task
      });

      this.notifySuccess(diff, 'users.stats.success');
    },
    async reopenTask() {
      const { diff } = await this.$store.dispatch(REOPEN_MISSION_TASK, {
        missionId: this.currentMission.id,
        taskId: this.task.id
      });

      this.notifyFailure(diff, 'users.stats.failure');
    },
    async editTask(event) {
      if (!this.isMissionActiveOrPending) {
        event.stopPropagation();
        return;
      }

      const payload = await this.$root.$modal.show(ManageMissionTaskModal, {
        selectedTask: { ...this.task }
      });

      if (!payload) return;

      await this.$store.dispatch(UPDATE_MISSION_TASK, {
        missionId: this.currentMission.id,
        ...payload
      });
    },
    async archiveTask() {
      await this.$store.dispatch(ARCHIVE_MISSION_TASK, {
        missionId: this.currentMission.id,
        taskId: this.task.id
      });

      this.$notify({
        type: 'success',
        text: this.$root.$t('tasks.taskArchived')
      });
    },
    async copyTask() {
      await this.$store.dispatch(COPY_MISSION_TASK, {
        missionId: this.currentMission.id,
        taskId: this.task.id
      });

      this.$notify({
        type: 'success',
        text: this.$root.$t('tasks.taskCloned')
      });
    },
    async deleteTask() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('tasks.deleteTaskConfirm')
      });

      if (!confirmed) return;

      this.$store.dispatch(DELETE_MISSION_TASK, {
        missionId: this.currentMission.id,
        taskId: this.task.id
      });
    }
  }
};
</script>
