<template>
  <div class="generated-tasks-list">
    <v-switch
      class="ml-1"
      v-model="allSelected"
      inset
      :label="$t('common.selectAll')"
      color="app-green"
      @change="toggleAll()"
    ></v-switch>
    <generated-mission-task
      class="mb-3"
      v-for="task of tasks"
      :key="task.name"
      :task="task"
      @toggleTask="toggleTask()"
    ></generated-mission-task>
    <div class="text-right">
      <v-btn v-if="hasSelectedTasks" color="app-blue" dark @click="addTasks()">
        {{ $t('tasks.addTasksToMission') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import GeneratedMissionTask from './GeneratedMissionTask';

export default {
  name: 'generated-mission-tasks-list',
  components: {
    GeneratedMissionTask
  },
  props: {
    tasks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allSelected: true
    };
  },
  computed: {
    hasSelectedTasks() {
      return this.tasks.some(task => task.selected);
    }
  },
  methods: {
    toggleAll() {
      this.tasks.forEach(task => {
        task.selected = this.allSelected;
      });
    },
    toggleTask() {
      this.allSelected = !this.tasks.some(task => !task.selected);
    },
    addTasks() {
      this.$emit('addTasksToMission', this.tasks);
    }
  }
};
</script>
