export default {
  missionTasks: {
    min: 2,
    max: 10
  },
  rewards: {
    min: 2,
    max: 10
  }
};
