<template>
  <div class="tasks-types-filter text-center">
    <v-chip
      v-for="filter of missionTasksTypesFilter"
      :key="filter.type"
      color="app-green"
      :outlined="!filter.selected"
      :dark="filter.selected"
      @click="selectFilter(filter)"
      class="mr-2"
    >
      {{ $t(`tasks.${filter.type}`) }}
    </v-chip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import commonVariables from '../../common/common.variables';

export default {
  name: 'mission-tasks-types-filter',
  computed: {
    ...mapGetters(['missionTasksTypesFilter'])
  },
  methods: {
    async selectFilter(filter) {
      this.$emit(commonVariables.events.onTasksFilterChange, filter.type);
    }
  }
};
</script>
