<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="manageMissionForm">
        <v-text-field
          solo
          v-model="mission.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
          :disabled="!canManageMission"
        ></v-text-field>
        <v-textarea
          solo
          v-model="mission.notes"
          :label="$t('common.notes')"
          :disabled="!canManageMission"
        ></v-textarea>
        <v-row justify="space-between">
          <v-col md="6" sm="12" cols="12" class="pb-md-2 pb-0">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-text-field
                  solo
                  v-model="mission.from"
                  :label="$t('common.from')"
                  prepend-inner-icon="mdi-calendar"
                  v-on="on"
                  :value="mission.from"
                  :error-messages="isFromDateValid"
                  :disabled="disableDates"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="mission.from"
                no-title
                :min="minDate"
                :disabled="disableDates"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" sm="12" cols="12" class="py-md-3 py-0">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-text-field
                  solo
                  v-model="mission.to"
                  :label="$t('common.to')"
                  prepend-inner-icon="mdi-calendar"
                  v-on="on"
                  :value="mission.to"
                  :rules="[rules.required]"
                  :error-messages="isToDateValid"
                  :disabled="disableDates"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="mission.to"
                no-title
                :min="minDate"
                :disabled="disableDates"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../common/validators';
import dateTimeManager from '../../common/date.time.manager';
import missionStatuses from '../../enums/mission.statuses';

export default {
  name: 'create-edit-mission-modal',
  props: {
    selectedMission: Object,
    copy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      if (this.mission.id) {
        return this.$i18n.t('missions.editMission');
      } else if (this.copy) {
        return this.$i18n.t('missions.cloneMission');
      }

      return this.$i18n.t('missions.createMission');
    },
    disableDates() {
      return this.mission.id && this.mission.status != missionStatuses.pending;
    },
    isFromDateValid() {
      if (this.mission.id && this.mission.status !== missionStatuses.pending) {
        return '';
      }

      const currentDate = dateTimeManager.toICODateString(new Date());

      if (dateTimeManager.isBefore(this.mission.from, currentDate)) {
        return this.$i18n.t('missions.fromDateBeforeCurrentDate');
      } else if (
        this.mission.to &&
        dateTimeManager.isAfter(this.mission.from, this.mission.to)
      ) {
        return this.$i18n.t('missions.fromDateAfterToDate');
      }

      return '';
    },
    isToDateValid() {
      if (this.mission.id && this.mission.status !== missionStatuses.pending) {
        return '';
      }

      if (this.mission.to) {
        return dateTimeManager.isBefore(this.mission.from, this.mission.to)
          ? ''
          : this.$i18n.t('missions.toDateBeforeFromDate');
      }

      return '';
    },
    rules() {
      return {
        required: validators.required(this.$i18n.t('common.requiredField'))
      };
    },
    canManageMission() {
      if (!this.mission.id) {
        return true;
      }

      return (
        this.mission.id &&
        (this.mission.status === missionStatuses.active ||
          this.mission.status === missionStatuses.pending)
      );
    }
  },
  data() {
    return {
      minDate: dateTimeManager.toICODateString(new Date()),
      missionStatuses,
      mission: {
        name: '',
        notes: '',
        from: dateTimeManager.toICODateString(new Date()),
        to: ''
      }
    };
  },
  created() {
    if (this.selectedMission) {
      if (!this.copy) {
        this.mission = {
          ...this.selectedMission,
          from: dateTimeManager.toICODateString(this.selectedMission.from),
          to: dateTimeManager.toICODateString(this.selectedMission.to)
        };
      } else {
        this.mission = {
          name: this.selectedMission.name,
          notes: this.selectedMission.notes,
          from: dateTimeManager.toICODateString(new Date()),
          to: ''
        };
      }
    }
  },
  methods: {
    formatDate(date) {
      return date ? dateTimeManager.formatDate(date) : '';
    },
    async save() {
      if (
        !this.$refs.manageMissionForm.validate() ||
        this.isFromDateValid ||
        this.isToDateValid
      ) {
        return;
      }

      this.$emit('close', this.mission);
    }
  }
};
</script>
