<template>
  <v-card tile>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ $t('tasks.generateTasks') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6">
      <p class="text-center">
        <strong>
          <span>{{ $t('tasks.generateTasksCostText') }}</span>
          <v-chip color="app-blue" text-color="white" class="pl-2 gems-label">
            <gems-label
              :gems="generationCost"
              textColor="white---text"
            ></gems-label>
          </v-chip>
        </strong>
      </p>
      <v-form ref="generateMissionTasksForm">
        <v-text-field
          solo
          :min="generatorCountsEnum.missionTasks.min"
          :max="generatorCountsEnum.missionTasks.max"
          :rules="[rules.required, rules.minMaxTasksCount]"
          v-model="tasksCount"
          :label="$t('tasks.tasksCountQuestion')"
          :hint="
            $t('common.minMaxValue', {
              min: generatorCountsEnum.missionTasks.min,
              max: generatorCountsEnum.missionTasks.max
            })
          "
        ></v-text-field>
        <v-row class="text-right">
          <v-col class="pt-0">
            <v-btn color="app-blue" dark @click="generateTasks()">
              {{ $t('tasks.generateTasks') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div v-if="generatedMissionTasks.length > 0" class="mt-5">
        <generated-mission-tasks-list
          :tasks="generatedMissionTasks"
          @addTasksToMission="addTasksToMission"
        ></generated-mission-tasks-list>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import validators from '@/common/validators';
import generatorCountsEnum from '../../enums/generator.counts';
import {
  GENERATE_MISSION_TASKS,
  ADD_MISSION_MULTIPLE_TASKS
} from '@/store/missions/tasks/types';
import GemsLabel from '../shared/GemsLabel';
import GeneratedMissionTasksList from './GeneratedMissionTasksList';
import avatarStoreModalStatus from '../../enums/avatar.store.modal.status';

export default {
  name: 'generate-mission-tasks-modal',
  components: {
    GemsLabel,
    GeneratedMissionTasksList
  },
  computed: {
    ...mapGetters(['currentMission', 'currentUser'])
  },
  data() {
    const { min, max } = generatorCountsEnum.missionTasks;

    return {
      tasksCount: null,
      generatorCountsEnum,
      generatedMissionTasks: [],
      generationCost: process.env.VUE_APP_OPENAI_CHATGPT_GENERATION_COST,
      loading: false,
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        minMaxTasksCount: validators.minMaxValue(
          min,
          max,
          this.$i18n.t('common.minMaxValue', {
            min,
            max
          })
        )
      }
    };
  },
  methods: {
    async generateTasks() {
      const { gems } = this.currentUser.stats;

      if (!this.$refs.generateMissionTasksForm.validate()) {
        return;
      }

      if (gems < this.generationCost) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('errors.notEnoughGemsToGenerateTasks')
        });

        this.$emit('close', { status: avatarStoreModalStatus.buyGems });
        return;
      }

      try {
        this.loading = true;

        this.generatedMissionTasks = await this.$store.dispatch(
          GENERATE_MISSION_TASKS,
          {
            missionId: this.currentMission.id,
            tasksCount: this.tasksCount
          }
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async addTasksToMission(tasks) {
      try {
        this.loading = true;

        await this.$store.dispatch(ADD_MISSION_MULTIPLE_TASKS, {
          missionId: this.currentMission.id,
          tasks: tasks.filter(task => task.selected)
        });

        this.loading = false;

        this.$emit('close');
      } catch (err) {
        this.loading = false;
      }
    }
  }
};
</script>
